export const DASHBOARD_LIST_ORDER_KEY =
  '@keen-dashboard-creator/dashboard-list-order';

export const PUBLIC_DASHBOARD_ID = '@keen-dashboard-creator';
export const FINISH_DASHBOARD_EDITION = '@keen-finish-dashboard-edition';

export const DASHBOARDS_ORDER = {
  recent: 'dashboard_list_order.recent',
  oldest: 'dashboard_list_order.oldest',
  az: 'dashboard_list_order.az',
  za: 'dashboard_list_order.za',
};
